import { IMAuthSDK } from '@jetprivilege/im-auth-sdk';
import config from '../config/config_backend.json';
import config_frontend from '../config/config_frontend.json';
import { v4 as uuidv4 } from "uuid";
import * as moment from "moment";
import Cookies from 'js-cookie';
import parseJwt from 'jwt-decode'

export function checkSession() {
    // LOADING HEADERFOOTER.JS FILE DYNAMICALLY
    loadJSDynamic(config.HeaderFooterLink.HeaderFooter)
        .then(() => {
            loadJSDynamic(config.HeaderFooterLink.JPPLCommonGlobal)
                .then(() => console.log('scripts loaded'))
        })

    if (config.isKeyCloakOn) {
        return checkKeyClokSession()
    }
    else {
        return checkAuth0Session()
    }
}

export function loginToggleModal() {
    const keyCloakConfig = {
        "url": config.keyCloak.url,
        "realm": config.keyCloak.realm,
        "clientId": config.keyCloak.clientId
    }
    let auth0Config = {
        "domain": config.auth0.domain,
        "clientID": config.auth0.clientID,
        "redirectUri": "",
        "responseType": config.auth0.responseType,
        "scope": config.auth0.scope,
        "audience": config.auth0.audience
    }
    const webauth0Login = new IMAuthSDK(keyCloakConfig, auth0Config, config.keyCloak.environment);
    webauth0Login.loginToggleModal();
}

async function loadJSDynamic(url) {
    return new Promise((resp, rej) => {
        // console.log("=========================================>>>> Load Header Call <<<<<<<<<<===================================")
        let e = document.createElement("script");
        e.src = url;
        e.type = "text/javascript";
        // e.addEventListener('load', callback);
        document.getElementsByTagName("head")[0].appendChild(e);
        resp(true);
    })
}

//function was not defined but used in this file
export function getCookieValue(cname, ckey) {
    if (typeof ($.cookie(cname)) !== 'undefined') {
        var valuearray = unescape($.cookie(cname)).split('&');
        for (var element of valuearray) {
            if (element.indexOf(ckey) >= 0) {
                var keyval = element.split('=');
                if (keyval.length > 1) {
                    return keyval[1];
                }
            }
        }
    }

    return "";
}

function checkAuth0Session() {
    console.log("Auth000000000000000000000");
    const auth0Config = {
        "ConfigUrl": "https://www.intermiles.com",
        "auth0": {
            "clientID": config.auth0.clientID,
            "domain": config.auth0.domain,
            "callbackURL": window.location.origin + config.auth0.callbackURL,
            "audience": "",
            "portalLoginUrl": config.auth0.portalLoginUrl,
            "logoutReturnUrl": window.location.origin,
            "externalReturnUrl": window.location.origin,
            "scope": "openid profile",
            "ssofilepath": "",
            "responseType": "token id_token"
        },
        "HeaderFooterLink": config.HeaderFooterLink
    };

    const webauth0 = new auth0.WebAuth({
        clientID: auth0Config.auth0.clientID,
        domain: auth0Config.auth0.domain,
        responseType: auth0Config.auth0.responseType,
        audience: auth0Config.auth0.audience,
        redirectUri: auth0Config.auth0.callbackURL,
        scope: auth0Config.auth0.scope
    });

    // const auth0Authentication = new auth0.Authentication(webauth0, {
    //     domain: auth0Config.auth0.domain,
    //     clientID: auth0Config.auth0.clientID
    // });

    return new Promise((resolve, reject) => {

        if (!getCookieValue('JSESSION', 'jsessionid')) {
            setSessionId(window);
        }
        window['sessionID'] = getCookieValue('JSESSION', 'jsessionid') ? getCookieValue('JSESSION', 'jsessionid') : '';
        webauth0.checkSession({
            responseType: 'id_token token',
            timeout: 10000,
            usePostMessage: true
        }, (err, authResult) => {
            if (err) {
                if (err.error === "Unauthorized" && err.error_description === "Log user out due to Auth Time expiry") {
                    window["MvpHeader"].auth0LogoutMS1();
                }
                localStorage.removeItem("access_token");
                localStorage.removeItem("UserSeesionNumber");
                let loggedIn_Info = { member_number: "", tier: "", isLoggedIn: false, token: null };
                initHeaderFooter(loggedIn_Info)
                resolve(loggedIn_Info)
            } else {
                resolve(setSessionValues(authResult));
            }
        })
    })
}

async function checkKeyClokSession() {
    console.log("keyCloakkkkkkkkkk");
    let auth0Config = {
        "domain": config.auth0.domain,
        "clientID": config.auth0.clientID,
        "redirectUri": window.location.origin + config.auth0.callbackURL,
        "responseType": config.auth0.responseType,
        "scope": config.auth0.scope,
        "audience": config.auth0.audience
    }
    const keyCloakConfig = {
        "url": config.keyCloak.url,
        "realm": config.keyCloak.realm,
        "clientId": config.keyCloak.clientId
    }


    const webauth0 = new IMAuthSDK(keyCloakConfig, auth0Config, config.keyCloak.environment);
    if (!getCookieValue('JSESSION', 'jsessionid')) {
        setSessionId(window)
    }
    window['sessionID'] = getCookieValue('JSESSION', 'jsessionid') ? getCookieValue('JSESSION', 'jsessionid') : '';
    return new Promise((resolve, reject) => {
        let silentCheckSSO = {}
        if (window.location.origin == "http://localhost:8080") {
            silentCheckSSO = {
                silentCheckSsoRedirectUri: config_frontend.Localhost + "im-sso/silent-check-sso.html"
            }
        }
        webauth0.checkSession(silentCheckSSO, auth0Config).then(authResult => {
            resolve(setSessionValues(authResult));
        }).catch(error => {
            console.log("keycloak error>>>>>>>", error);
            webauth0.auth0LogoutMS1();
            localStorage.removeItem("access_token");
            localStorage.removeItem("UserSeesionNumber");
            let loggedIn_Info = { member_number: "", tier: "", isLoggedIn: false, token: null };
            initHeaderFooter(loggedIn_Info)
            resolve(loggedIn_Info)
        })
    })
}

function setSessionValues(authResult) {
    let token = null;
    let userDetailsInfo = {};
    token = authResult.idToken

    let loggedIn_Info = { member_number: "", tier: "", isLoggedIn: false, token: token };
    console.log("authResult========================>", authResult);

    if (authResult && authResult.accessToken && authResult.idToken) {
        let namespace = "https://www.intermiles.com";
        let NameSpaceIdentifier = "https://www.intermiles.com" + "/";
        let OIDC = "";
        let pointsBalance = "";
        console.log("authResult", authResult)

        if (authResult.idTokenPayload[namespace + "/OIDC"] != undefined &&
            authResult.idTokenPayload[namespace + "/OIDC"] === "ON") {
            let idTokenPayload = JSON.stringify(authResult.idTokenPayload);
            let regex = new RegExp(NameSpaceIdentifier, "g");
            authResult.idTokenPayload = JSON.parse(idTokenPayload.replace(regex, ""));
            OIDC = "ON";
            pointsBalance = authResult.idTokenPayload.cris_Member_PointsBalance;
            authResult.idTokenPayload.cris_Member_PointsBalanace = authResult.idTokenPayload.cris_Member_PointsBalance;
        } else {
            OIDC = "OFF";
            pointsBalance = authResult.idTokenPayload.cris_Member_PointsBalanace;
        }

        userDetailsInfo = authResult.idTokenPayload;
        //isLoggedIn = true;
        window['UD'] =
        {
            "JPNumber": authResult.idTokenPayload.user_id.replace("auth0|00", ""),
            "name": authResult.idTokenPayload.cris_Member_FirstName,
            "PointsBalanace": pointsBalance,
            "tier": authResult.idTokenPayload.cris_Member_Tier,
            "EmailVerified": authResult.idTokenPayload.cris_Member_IsEmailVerified,
            "MobileVerified": authResult.idTokenPayload.cris_Member_IsMobileVerified,
            "PersonId": authResult.idTokenPayload.cris_Member_PersonID,
            "activeStatus": authResult.idTokenPayload.cris_Member_ActiveStatus,
            "age": "uSdKMmg8GbYjn6v/l43giQ#XXX##XXX#3d0501a563e4", //Encryptred value
            "cityofResidence": "700IPHr0vRsTZAs4fP+Zxw#XXX##XXX#3d0501a563e4", //Encryptred value
            "countryofResidence": "8kUut5dL2Ptwns5+6wL9wQ#XXX##XXX#3d0501a563e4", //Encryptred value
            "gender": "e0lmJRyXv5xxF45vfNPChw#XXX##XXX#3d0501a563e4", //Encryptred value
            "firstloginDate": authResult.idTokenPayload.created_at,
            "lastloginDate": authResult.idTokenPayload.cris_Member_LastLoginDate,
            "memProgram": authResult.idTokenPayload.cris_Member_MemProgram,
            "LoggedInUsing": "JPNO"
        };
        window['JPNumber'] = authResult.idTokenPayload.user_id.replace("auth0|00", "")
        window['JPNo'] = authResult.idTokenPayload.user_id.replace("auth0|00", "")
        window['OIDC'] = OIDC;
        localStorage.setItem("fs", authResult.idTokenPayload.created_at);
        localStorage.setItem("ls", authResult.idTokenPayload.cris_Member_LastLoginDate);

        console.log("userDetailsInfo=======================>", userDetailsInfo);
        if (userDetailsInfo && userDetailsInfo.nickname) {
            loggedIn_Info = {
                member_number: userDetailsInfo.nickname,
                tier: userDetailsInfo["cris_Member_Tier"],
                isLoggedIn: true,
                email: userDetailsInfo["cris_Member_EmailAddress"],
                first_name: userDetailsInfo["cris_Member_FirstName"],
                last_name: userDetailsInfo["cris_Member_LastName"],
                mobile_number: userDetailsInfo["cris_Member_MobileNumber"],
                member_title: userDetailsInfo["cris_Member_Title"],
                points_balance: userDetailsInfo["cris_Member_PointsBalance"],
                gender: userDetailsInfo["cris_Member_Gender"],
                country: userDetailsInfo["cris_Member_CountryOfResidence"],
                at_hash: userDetailsInfo["at_hash"] || '',
                PID: userDetailsInfo["cris_Member_PersonID"],
                token: token
            }
            // SETTING THE TOKEN IN LC FOR FURTHER PAGES USE
            localStorage.setItem("access_token", token)
            // SETTING MEMEBR NUMBER TO LS
            localStorage.setItem("UserSeesionNumber", authResult.idTokenPayload.user_id.replace("auth0|00", ""))

        } else {
            localStorage.removeItem("UserSeesionNumber");
            localStorage.removeItem("access_token")
        }
    }
    console.log("loggedIn_Info================================>", loggedIn_Info);
    initHeaderFooter(loggedIn_Info)
    return loggedIn_Info
}


async function initHeaderFooter(loggedIn_Info) {
    // SET GLOBAL CT DATA
    setCTGlobalLoggedIn(loggedIn_Info.token)
    // INIT CT
    initCT(loggedIn_Info.token)
}


export function setSessionId(windowObject) { // let jsessionid = this.uuidv4();
    return new Promise((resolve, reject) => {
        let jsessionid = "";
        let finalReturnValue = "";
        var sessiontime = localStorage.getItem("sessiont");
        // console.log("sessiontime", sessiontime);
        var expirytime = getcurrenttime();
        if (windowObject.navigator.userAgent.indexOf(".NET") != -1) {
            if (sessiontime != "" && sessiontime != null && sessiontime != "null") {

                let validttime = moment.utc(moment(expirytime, "mm").diff(moment(sessiontime, "mm"))).format("mm");

                if (Number(validttime) < 10) {
                    // console.log("valid");
                    jsessionid = localStorage.getItem("sessionurl");
                } else {
                    // console.log("INvalid");
                    jsessionid = uuidv4_ie();
                }
            } else {
                jsessionid = uuidv4_ie();
            }
        } else { // //console.log("chrome--mozilla-safari");
            if (sessiontime != "" && sessiontime != null && sessiontime != "null") {

                let validttime = moment.utc(moment(expirytime, "mm").diff(moment(sessiontime, "mm"))).format("mm");

                if (Number(validttime) < 10) {
                    // console.log("valid");
                    jsessionid = localStorage.getItem("sessionurl");
                } else {
                    // console.log("INvalid");
                    jsessionid = uuidv4();
                }
            } else {
                jsessionid = uuidv4();
            }
        }
        let d = new Date();
        d.setTime(d.getTime() + (2 * 24 * 60 * 60 * 1000));
        let ctime = "expires=" + d.toUTCString();
        let sessid = "jsessionid=" + jsessionid + "&jsessiontime=" + new Date();
        let sessid1 = jsessionid;
        document.cookie = "JSESSION=" + encodeURIComponent(sessid) + "; " + ctime + "; path=/";
        // console.log("JSESSION", Cookies.get('SESSION_ID'));
        if ((Cookies.get("SESSION_ID") != undefined)) {
            // console.log("JSESSION1", Cookies.get("SESSION_ID"));
            finalReturnValue = Cookies.get("SESSION_ID");
        } else {
            // console.log("JSESSION2", Cookies.get("SESSION_ID"), "sessid1", sessid1);
            document.cookie = "SESSION_ID=" + encodeURIComponent(sessid1);
            finalReturnValue = encodeURIComponent(sessid1);
            localStorage.setItem("session_id", sessid1);
            localStorage.setItem("sessiont", getcurrenttime());
            localStorage.setItem("sessionurl", Cookies.get("SESSION_ID"));
            // console.log("JSESSION3", Cookies.get("SESSION_ID"));
        }
        resolve(finalReturnValue);
    });
}

function getcurrenttime() {
    var current_time = moment().format("mm");
    return current_time;
}

function uuidv4_ie() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function setCTGlobalLoggedIn(data) {
    try {
        if (window.clevertapData == undefined) window.clevertapData = {};
        if (window.clevertapData["Global"] == undefined) window.clevertapData["Global"] = {};
        window.clevertapData["Global"]['Login Status'] = (data !== null) ? "Logged In" : "Anonymous";
    } catch (e) {
        console.log("ctlogin:", e);
    }
}

function initCT(token) {
    try {
        window.clevertapData = window.clevertapData || {};
        var isMobile = window.screen.availWidth <= 1023 ? true : false;
        if (
            localStorage.getItem("clearall") == undefined ||
            localStorage.getItem("clearall") == null
        ) {
            localStorage.clear();
            localStorage.setItem("clearall", true);
        }
        let data2 = token;
        window.clevertapData["pointsBalance"] = 0;
        let ConfigUrl = "` + Url.ConfigUrl + `";
        if (data2 !== null) {
            let data1 = parseJwt(data2);
            window.clevertapData["pointsBalance"] = data1.cris_Member_PointsBalanace;
            if (data1[ConfigUrl + "OIDC"] == "OFF") {
                let StoreData = {
                    JPNumber: data1.user_id.replace("auth0|00", ""),
                    pointsBalance: data1.cris_Member_PointsBalanace,
                    pid: "",
                    usernameType: "JPNO",
                    firstloginDate: data1.created_at,
                    lastloginDate: data1.cris_Member_LastLoginDate,
                    cookieAccept: "",
                    cookieDateTime: "",
                    loginStatus:
                        data1[ConfigUrl + "cris_Member_ActiveCardNo"] !== ""
                            ? "LOGGED-IN"
                            : "ANONYMOUS",
                    EmailVerified: data1.cris_Member_IsEmailVerified,
                    MobileVerified: data1.cris_Member_IsMobileVerified,
                    PersonId: data1.cris_Member_PersonID,
                    activeStatus: data1.cris_Member_ActiveStatus,
                    age: "uSdKMmg8GbYjn6v/l43giQ#XXX##XXX#3d0501a563e4", //Encryptred value
                    gender: "e0lmJRyXv5xxF45vfNPChw#XXX##XXX#3d0501a563e4", //Encryptred value
                    cityofResidence: "700IPHr0vRsTZAs4fP+Zxw#XXX##XXX#3d0501a563e4", //Encryptred value
                    countryofResidence: "8kUut5dL2Ptwns5+6wL9wQ#XXX##XXX#3d0501a563e4", //Encryptred value
                    memProgram: data1.cris_Member_MemProgram,
                    tier: data1.cris_Member_Tier,
                    SessionDateTime: new Date().toISOString(),
                    identifierType: (data1.user_metadata && data1.user_metadata.loginwith) ? data1.user_metadata.loginwith : '',
                    hashedEmailID: "",
                    hashedMobile: "",
                    loginScenario: "",
                    preassignedJPNumFlag: "",
                    CookieAcceptedProperty: true,
                };

                let marketingInfo = {
                    sourceCode: "",
                    mediaCode: "",
                    vintage: "",
                    campaignCode: "",
                };
                window.digitalData = {
                    userInfo: StoreData,
                    marketingInfo: marketingInfo,
                };
            } else {
                let StoreData = {
                    JPNumber:
                        data1[ConfigUrl + "user_id"] == undefined
                            ? ""
                            : data1[ConfigUrl + "user_id"].replace("auth0|00", ""),
                    PointsBalanace: data1[ConfigUrl + "cris_Member_PointsBalance"],
                    pid: "",
                    usernameType: "JPNO",
                    firstloginDate: data1[ConfigUrl + "created_at"],
                    lastloginDate: data1[ConfigUrl + "cris_Member_LastLoginDate"],
                    cookieAccept: "",
                    cookieDateTime: "",
                    loginStatus:
                        data1[ConfigUrl + "cris_Member_ActiveCardNo"] !== ""
                            ? "LOGGED-IN"
                            : "ANONYMOUS",
                    EmailVerified: data1[ConfigUrl + "cris_Member_IsEmailVerified"],
                    MobileVerified: data1[ConfigUrl + "cris_Member_IsMobileVerified"],
                    PersonId: data1[ConfigUrl + "cris_Member_PersonID"],
                    activeStatus: data1[ConfigUrl + "cris_Member_ActiveStatus"],
                    age: "uSdKMmg8GbYjn6v/l43giQ#XXX##XXX#3d0501a563e4", //Encryptred value
                    gender: "e0lmJRyXv5xxF45vfNPChw#XXX##XXX#3d0501a563e4", //Encryptred value
                    cityofResidence: "700IPHr0vRsTZAs4fP+Zxw#XXX##XXX#3d0501a563e4", //Encryptred value
                    countryofResidence: "8kUut5dL2Ptwns5+6wL9wQ#XXX##XXX#3d0501a563e4", //Encryptred value
                    memProgram: data1[ConfigUrl + "cris_Member_MemProgram"],
                    tier: data1[ConfigUrl + "cris_Member_Tier"],
                    SessionDateTime: new Date().toISOString(),
                    identifierType: (data1.user_metadata && data1.user_metadata.loginwith) ? data1.user_metadata.loginwith : '',
                    hashedEmailID: "",
                    hashedMobile: "",
                    loginScenario: "",
                    preassignedJPNumFlag: "",
                    CookieAcceptedProperty: true,
                };
                let marketingInfo = {
                    sourceCode: "",
                    mediaCode: "",
                    vintage: "",
                    campaignCode: "",
                };
                window.digitalData = {
                    userInfo: StoreData,
                    marketingInfo: marketingInfo,
                };
            }
        }
        else {
            let StoreData = {
                JPNumber: "",
                PointsBalanace: "",
                pid: "",
                usernameType: "",
                firstloginDate: "",
                lastloginDate: "",
                cookieAccept: "",
                cookieDateTime: "",
                loginStatus: "",
                EmailVerified: "",
                MobileVerified: "",
                PersonId: "",
                activeStatus: "",
                age: "", //Encryptred value
                gender: "", //Encryptred value
                cityofResidence: "", //Encryptred value
                countryofResidence: "", //Encryptred value
                memProgram: "",
                tier: "",
                // "sessionID":"",
                SessionDateTime: "",
                identifierType: "",
                hashedEmailID: "",
                hashedMobile: "",
                loginScenario: "",
                preassignedJPNumFlag: "",
                CookieAcceptedProperty: "",
            };
            let marketingInfo = {
                sourceCode: "",
                mediaCode: "",
                vintage: "",
                campaignCode: "",
            };

            window.digitalData = {
                userInfo: StoreData,
                marketingInfo: marketingInfo,
            };
        }
        // window.digitalData = { eventInfo: {},propertyInfo:{},pageInfo:{}};


        window.clevertapData["Global"] = {
            Channel: isMobile ? "Mweb" : "Web",
            Property: "Interbook",
            "CleverTap ID": "",
            "Login Status": data2 !== null ? "Logged In" : "Anonymous",
        };
    } catch (e) {
        console.log("ct err:", e);
    }

}